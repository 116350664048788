:root {
    --primaryBlue: #0b6df6;
    --darkBlue: #1e4763;
    --lightGrey: #d1d1d5;
    --darkGrey: #6c8899;
    --lightblue: #80d7ff;
    --falseBlack: #041824;
    --white: #FFFFFF;
    --black: #000000;
    --green: #298b50;
    --yellow: #c0ad4a;
    --red: #cd0000;
    --secondaryPadding: 10px;
    --secondaryMargin: 10px;
    --mainMargin: 20;
    --mainPadding: 20;
    --gap: 15px;
    --h1: 25px;
    --h2: 20px;
    --h3: 16px;
    --h4: 14px;
    --h5: 12px;
    --h6: 10px;
}