@import url(constants/root.css);

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(45deg, var(--falseBlack), var(--black));
  background-size: 100%;
  color: var(--white);
  min-height: 100vh;
  overflow: scroll;
}

* {
  font-family: "ubuntu" !important;
}

*::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none !important;
}